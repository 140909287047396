<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Cuentas contables </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-tabs type="is-boxed">
      <b-tab-item
        label="Cuentas contables generales"
        icon="ticket-confirmation-outline"
      >
        <GeneralContableAccounts />
      </b-tab-item>
      <b-tab-item label="Ventas de contado" icon="cash">
        <CashSalesPoliciesList />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import GeneralContableAccounts from "@/modules/Accounting/components/GeneralContableAccounts.vue";
import CashSalesPoliciesList from "@/modules/Accounting/components/CashSalesPoliciesList.vue";

export default {
  name: "ContableAccounts",
  components: {
    GeneralContableAccounts,
    CashSalesPoliciesList,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "countableAccounts");
  },
  async created() {},
  methods: {},
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return null;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
