<template>
  <div class="container configuration-form-container">
    <div class="control add-global-controls">
      <b-button
        type="is-primary"
        @click="addIepsContableAccount()"
        class="no-margin-bottom special-margin-top"
      >
        Añadir cuenta contable de IEPS
      </b-button>
    </div>

    <div class="configuration-form-hint-container">
      <p class="configuration-form-hint">
        En esta sección podrás modificar las cuentas contables generales de tu
        empresa. Los campos requeridos tienen el marco
      </p>
      <span class="global-form-hint-color"> Morado. </span>
    </div>

    <h3 class="configuration-form-division">Cuentas contables generales</h3>

    <div class="configuration-divided-information special-margin">
      <div
        v-for="(singleContableAccounts, index) in contableAccounts"
        :key="`${singleContableAccounts.TIPO}${singleContableAccounts.CANTIDAD}`"
      >
        <b-field
          grouped
          class="special-margin special-margin-top"
          v-if="singleContableAccounts.TIPO !== 'CUENTA_CONTABLE_IEPS'"
        >
          <b-field
            :label="`${singleContableAccounts.TITULO}`"
            expanded
            label-position="inside"
          >
            <b-input
              placeholder="Ejemplo: 12345"
              expanded
              icon="numeric-3-box-multiple-outline"
              v-model="contableAccounts[index].CUENTA_CONTABLE"
            ></b-input>
          </b-field>
        </b-field>
      </div>
    </div>

    <h3 class="configuration-form-division">Cuentas contables IEPS</h3>

    <div class="configuration-divided-information special-margin">
      <div
        v-for="(singleContableAccounts, index) in contableAccounts"
        :key="`${singleContableAccounts.TIPO}${singleContableAccounts.CANTIDAD}`"
      >
        <b-field
          grouped
          class="special-margin special-margin-top"
          v-if="singleContableAccounts.TIPO === 'CUENTA_CONTABLE_IEPS'"
        >
          <b-field
            :label="`Cuenta contable IEPS ${singleContableAccounts.CANTIDAD}%`"
            expanded
            label-position="inside"
          >
            <b-input
              placeholder="Ejemplo: 12345"
              expanded
              icon="numeric-3-box-multiple-outline"
              v-model="contableAccounts[index].CUENTA_CONTABLE"
            ></b-input>
          </b-field>
          <b-field
            :label="`Porcentaje de IEPS de cuenta contable`"
            expanded
            label-position="inside"
          >
            <PercentageInput
              placeholder="Ejemplo: 4"
              expanded
              type="number"
              step="any"
              icon="numeric-3-box-multiple-outline"
              v-model="contableAccounts[index].CANTIDAD"
            />
          </b-field>
        </b-field>
      </div>
    </div>

    <div class="configuration-add-global-controls">
      <b-button type="is-success" @click="saveContableAccounts()"
        >Guardar cambios</b-button
      >
    </div>
  </div>
</template>

<script>
import InformationCard from "@/modules/Global/components/InformationCard.vue";

const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

import Cleave from "cleave.js";
import { fillObjectForm } from "@/utils/component-fns";
// @ is an alias to /src
export default {
  name: "GeneralContableAccounts",
  props: [],
  components: {},
  directives: { cleave },
  data() {
    return {
      contableAccounts: [
        {
          TIPO: "CUENTA_CONTABLE_DEVOLUCIONES",
          TITULO: "Cuenta contable de devoluciones (Opcional)",
          CUENTA_CONTABLE: "",
          CANTIDAD: 0,
        },
        {
          TIPO: "CUENTA_CONTABLE_DE_MAS",
          TITULO: "Cuenta contable pagado de más (Opcional)",
          CUENTA_CONTABLE: "",
          CANTIDAD: 0,
        },
        {
          TIPO: "CUENTA_CONTABLE_NO_MARCO",
          TITULO: "Cuenta contable de lo que no se marco (Opcional)",
          CUENTA_CONTABLE: "",
          CANTIDAD: 0,
        },
      ],
      contableAccountsRequiredErrors: {},
    };
  },
  mounted() {
    this.refreshInformation();
    // Fill the brand information in form fields
    if (this.globalContableAccounts.length > 0) {
      // Fill the form with received information
      this.contableAccounts = this.globalContableAccounts;
    }
  },
  methods: {
    async refreshInformation() {
      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETCONTABLEACCOUNTS"));

      await Promise.all(allPromises);
    },
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc saves or updates a new brand depending on the type
     */
    async saveContableAccounts() {
      try {
        let response = await this.$store.dispatch("SAVECONTABLEACCOUNTS", {
          contableAccounts: this.contableAccounts,
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se guardaron exitosamente las cuentas contables",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {},
            cancelText: "cancelar",
            canCancel: false,
          });
          await this.refreshInformation();
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar las cuentas contables en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar las cuentas contables en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    addIepsContableAccount() {
      this.contableAccounts.push({
        TIPO: "CUENTA_CONTABLE_IEPS",
        CUENTA_CONTABLE: "",
        CANTIDAD: 0,
      });
    },
  },
  computed: {
    globalContableAccounts() {
      return this.$store.getters.CONTABLEACCOUNTS;
    },
  },
  watch: {
    globalContableAccounts() {
      // Fill the brand information in form fields
      if (this.globalContableAccounts.length > 0) {
        // Fill the form with received information
        this.contableAccounts = this.globalContableAccounts;
      }
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Configuration/style/Configuration.css";
@import "../../Global/style/InitialDashboard.css";
</style>
